<!-- Template -->
<template>
  <svg
    class="i-warning"
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 20 20"
  >
    <path
      id="Caminho_1485"
      data-name="Caminho 1485"
      d="M8.784,14.64h1.952V8.784H8.784ZM9.76,0a9.76,9.76,0,1,0,9.76,9.76A9.789,9.789,0,0,0,9.76,0Zm0,17.568A7.808,7.808,0,1,1,17.568,9.76,7.831,7.831,0,0,1,9.76,17.568ZM8.784,6.832h1.952V4.88H8.784Z"
      :fill="fill"
    />
  </svg>
</template>

<!-- Scripts -->
<script>
// Componente
export default {
  // Nome do componente
  name: "IconWarning",
  // Propriedades herdadas
  props: {
    width: { type: Number, default: 20 },
    height: { type: Number, default: 20 },
    fill: { type: String, default: '#ff4040' },
  },
}
</script>